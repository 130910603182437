<template>
  <b-card
    class="mb-2"
    no-body
  >
    <div class="custom-search d-flex justify-content-end p-1">
      <b-form-group
        class="mb-0"
      >
        <b-input-group size="lg">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
        </b-input-group>
      </b-form-group>
      <b-button
        variant="primary"
        class="ml-1"
        @click="getRules"
      >
        <feather-icon
          icon="RefreshCwIcon"
        />
        refresh
      </b-button>
    </div>
    <b-table
      responsive
      hover
      outlined
      primary-key="id"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      :filter="filter"
    >
      <!-- Column: User -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small v-if="data.item.modelID" class="text-muted">{{ data.item.modelID }}</small>
          <b-progress
            v-if="data.item.status == 'In progress'"
            :value="100"
            variant="success"
            striped
            :animated="true"
          />
        </b-media>
      </template>
      <template #cell(actions)="row">
        <BIconCheck
          v-if="row.item.status !== 'active'"
          class="edit-icon mr-1"
          variant="success"
          scale="2.5"
          @click="handleActivate(row.item)"
        />

        <BIconCheck2Circle
          class="edit-icon mr-1 ml-1"
          variant="success"
          scale="2.5"
          :title="`Click to activate FFT model for ${row.item.assetID}`"
          @click="activateFFTModel(row.item)"
        />

        <BIconTrash
          class="remove-icon ml-1"
          variant="danger"
          scale="1.5"
          @click="handleDelete(row.item)"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BIconTrash,
  BIconCheck,
  BButton,
  BCard,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BLink,
  BMedia,
  BProgress,
  BIconCheck2Circle,

} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
import ruleStore from './trainingStore'

export default {
  components: {
    BIconTrash,
    BIconCheck,
    BButton,
    BCard,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BLink,
    BMedia,
    BProgress,
    BIconCheck2Circle,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'fault-code-list'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, ruleStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    const isBusy = ref(false)
    const items = ref([])
    const sortBy = ref('assetID')
    const sortDesc = ref(true)
    const filter = ref('')
    const fields = [
      {
        key: 'assetID',
        label: 'Asset',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'name',
        label: 'Model',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'user',
        label: 'Updated By',
        sortable: true,
      },
      {
        key: 'createdAt',
        label: 'Created',
        sortable: true,
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'updatedAt',
        label: 'Updated',
        sortable: true,
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
      },
    ]

    const getRules = () => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchTrainingStatus`)
        .then(response => {
          items.value = response.data
        })
        .catch(error => {
          console.log(error)
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while fetching training results',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getRules()

    const rowCancel = data => {
      // delete the item if it is found in the items array
      const index = items.value.findIndex(item => item.id === data.id)
      if (index > -1) {
        items.value.splice(index, 1)
      }
    }

    const handleDelete = data => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/deleteTrainingStatus`, data.id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              message: 'Data deleted successfully',
            },
          })
          getRules()
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while deleting data',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const handleActivate = data => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/updateTrainingStatus`, data)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              message: 'Record activated successfully',
            },
          })
          getRules()
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while activating the record',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    const activateFFTModel = data => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/activateFFTModel`, data)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              message: 'Record activated successfully',
            },
          })
          getRules()
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while activating the record',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    return {
      isBusy,
      items,
      sortBy,
      sortDesc,
      filter,
      fields,
      rowCancel,
      handleDelete,
      handleActivate,
      activateFFTModel,
      getRules,
    }
  },
}
</script>
